import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter);

const router = new VueRouter({
    mode: "history",
    routes: [
        { 
            path: "/",
            name: "home", 
            component: () => import('../components/home')
        },
        { 
            path: "/news",
            name: "news", 
            component: () => import('../components/news.vue')
        },
        { 
            path: "/news/:name",
            name: "newsmore",
            component: () => import('../components/news-more.vue')
        },
        {
            path:'/petition',
            name: 'petition',
            component: () => import('../components/Petition.vue')
            
        },
        { 
            path: "/thanks",
            name: "thanks",
            component: () => import('../components/ThanksPage.vue')
        },
        { 
            path: "/InformationPlacement",
            name: "InformationPlacement", 
            component: () => import('../components/InformationPlacementComponent.vue')
        },
    ],
    navigationFallback: {
        rewrite: "/index.html",
        exclude: ["*.{css, svg, js, ts, png, jpeg, jpg, ico}"]
    },
    scrollBehavior(to) {
    if (to.hash) {
        return {
        selector: to.hash,
        behavior: 'smooth', // Optional: for smooth scrolling
        };
    }
    return { x: 0, y: 0 };
    },
})

export default router