<template>
<div id="app">
    <!-- <news></news> -->
    <router-view></router-view>
    <!-- <notificationModal></notificationModal> -->
    <!-- <b-modal v-if="screenWith > 768" id="bv-modal-example" hide-footer>
        <div>
            <h2 class="modal--title">{{ translation.translate('Modal','modal--title') }}</h2>
            <p class="modal--description">{{ translation.translate('Modal','modal--description') }}</p>
        </div>

        <div class="boxChanel">
            <img src="./assets/images/modal.png" alt="png">
        </div>

        <div class="d-flex justify-content-center">
            <div class="d-flex justify-content-between" style="width: 60%;">
                <b-button class="btnCloseInModal" block @click="$bvModal.hide('bv-modal-example')">{{ translation.translate('Modal','modal--close-btn') }}</b-button>
                <b-button class="btnInstructionInModal" block><a href="https://t.me/+3hI-LWMYQrMwODcy" target="_blank" class="aModal">{{ translation.translate('Modal','modal--follow-btn') }}</a></b-button>
            </div>
        </div>
    </b-modal> -->

</div>
</template>

<script>
import translate from '../translation/translate'

export default {
    name: 'App',
    data() {
        return {
            screenWith: window.innerWidth,
            translation: translate,
        }
    },
    mounted() {
        if (this.screenWith > 768) this.$bvModal.show('bv-modal-example')
    }

}
</script>

<style scoped>
@media (max-width: 766px) {
    .modal {
        display: none !important;
    }

    #bv-modal-example {
        display: none;
    }

    .modal-dialog {
        display: none;
    }

    #bv-modal-example___BV_modal_content_ {
        display: none !important;
    }
}

.modal-header .close {
    margin: 0.5rem -1rem -1rem auto !important;
}

.buttonModal:hover {
    box-shadow: 0 0 12px #72bd49 !important;
}

.buttonModal {
    color: white !important;
    padding: 8px 20px !important;
    border: none !important;
    border-radius: 30px !important;
    background: #72bd49 !important;
    transition: 500ms !important;
}

.modal--images {
    width: 90%;
    border-radius: 15px;
    box-shadow: 0 0 8px rgb(195, 194, 194);
    margin: 0 20px 0 20px;
}

.modal-header .close {
    margin: 0.5 rem -1rem -1rem auto !important;
}

.textRectangle {
    font-weight: bold;
    font-size: 14px;
    line-height: 16px;
    text-align: center;
    color: #676767;
    margin: 15px 0;
}

.pngRectangle {
    width: 100px;
    height: 100px;
}

.boxRectangle {
    background: #F2FFEB;
    border-radius: 15px;
    box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.25);
    padding: 20px;
}

.columnPrize {
    margin-top: 40px;
}

.columnPrize .place {
    font-weight: 600;
    font-size: 20px;
    line-height: 23px;
    text-align: center;
    color: #5B5B5B;
    margin-top: 10px;
}

.textPrize {
    font-weight: bold;
    font-size: 20px;
    line-height: 23px;
    text-align: center;
    margin-bottom: 0;
}

.yellow {
    color: #FECC2F;
}

.blue {
    color: #209FFF;
}

.red {
    color: #FE3D00;
}

.boldFont {
    font-weight: bold;
}

.aModal {
    color: white;
}
</style>
