import Vue from 'vue'
import App from './App.vue'
import VueRouter from 'vue-router'
import router from '../src/router/router'

import VueGtag from "vue-gtag";

Vue.use(VueGtag, {
  config: { id: "UA-108160101-2" }
});

import 'bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';

import '@/assets/global.css'
import '@/assets/media.css'
import { BootstrapVue, IconsPlugin } from 'bootstrap-vue'
import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-vue/dist/bootstrap-vue.css'
import './assets/fonts/LabGrotesque/stylesheet.css'
import VueMeta from 'vue-meta'


// -----------------------------------------------
Vue.use(VueRouter)
Vue.use(BootstrapVue)
Vue.use(IconsPlugin)
Vue.use(VueMeta)
require("../translation/translate");

new Vue({
  router,
  render: h => h(App),
}).$mount('#app')
